// Generated by Framer (91d32d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["eK82XRruM", "wH7AsV4YC"];

const serializationHash = "framer-QXUJu"

const variantClassNames = {eK82XRruM: "framer-v-aeblmr", wH7AsV4YC: "framer-v-1bm0xgb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "eK82XRruM", "Variant 2": "wH7AsV4YC"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "eK82XRruM"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "eK82XRruM", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Image {...restProps} animate={variants} background={{alt: "A man and a robot shaking hands over a cityscape, symbolizing a successful business partnership.", fit: "fill", intrinsicHeight: 824, intrinsicWidth: 1110, pixelHeight: 824, pixelWidth: 1110, src: "https://framerusercontent.com/images/Kt7cAM6VXlaIIsL91hq2YqtsA.jpg"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-aeblmr", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"eK82XRruM"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: 7, borderBottomRightRadius: 7, borderTopLeftRadius: 7, borderTopRightRadius: 7, ...style}} {...addPropertyOverrides({wH7AsV4YC: {"data-framer-name": "Variant 2", background: {alt: "A man and a robot shaking hands over a cityscape, symbolizing a successful business partnership.", fit: "fill", intrinsicHeight: 800, intrinsicWidth: 1200, pixelHeight: 800, pixelWidth: 1200, src: "https://framerusercontent.com/images/sKZaDgAdVrVmPzZMcyC3dukXkZ4.jpg"}}}, baseVariant, gestureVariant)}/></Transition>
</LayoutGroup>)

});

const css = [".framer-QXUJu[data-border=\"true\"]::after, .framer-QXUJu [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QXUJu.framer-1mtd6uw, .framer-QXUJu .framer-1mtd6uw { display: block; }", ".framer-QXUJu.framer-aeblmr { height: 412px; overflow: visible; position: relative; width: 558px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 412
 * @framerIntrinsicWidth 558
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"wH7AsV4YC":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerTaxhQ79Ch: React.ComponentType<Props> = withCSS(Component, css, "framer-QXUJu") as typeof Component;
export default FramerTaxhQ79Ch;

FramerTaxhQ79Ch.displayName = "Image";

FramerTaxhQ79Ch.defaultProps = {height: 412, width: 558};

addPropertyControls(FramerTaxhQ79Ch, {variant: {options: ["eK82XRruM", "wH7AsV4YC"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerTaxhQ79Ch, [])